
  export default {
    name: `LogoutPage`,
    mounted() {
      this.$store.dispatch(`logout`)
      this.logout()
    },
    methods: {
      logout() {
        this.$auth.logout()
      }
    }
  }
